<template>
  <div class="d-flex justify-center">
    <GeneratePayroll
      ref="generatePayroll"
      @close="closeDialog"
      :isOpen="isOpen"
    />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <inline-svg :src="require('@/assets/svg/payroll-white.svg')" /> +
      </div>
      Generate Payroll
    </SecondaryButton>
  </div>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";
import GeneratePayroll from "@/views/finance/components/Generate/GeneratePayroll";

export default {
  components: {
    InlineSvg,
    SecondaryButton,
    GeneratePayroll
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
      document.title = "Generate Payroll";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = "Payroll";
    }
  }
};
</script>
