<template>
  <div class="list-item with-icon mb-1">
    <v-list-item-avatar min-width="60px" height="60px" tile>
      <v-img height="30px" contain src="@/assets/svg/payroll.svg" />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description py-1" :class="{ 'flex-column': isMobile }">
          <div class="d-flex align-center mb-1">
            <h3>to {{ payroll.periodEndDate | fullDate }}</h3>
          </div>
          <div class="d-flex align-center">
            <span class="ml-1"> {{ payroll.organisationName }}</span>
          </div>
        </div>
        <div
          class="d-flex"
          :class="{ 'flex-column': isMobile, 'align-center': !isMobile }"
        >
          <div class="d-flex flex-column" :class="{ 'align-end': !isMobile }">
            <span class="text-mono mb-1">PR-{{ payroll.id }}</span>
          </div>
          <div
            class="download d-flex ml-2 my-2"
            :class="{ 'justify-center': !isMobile }"
          >
            <v-img
              v-if="!downloading"
              @click="downloadFile"
              class="download__icon"
              height="20px"
              contain
              src="@/assets/svg/download.svg"
            />
            <v-progress-circular v-else indeterminate color="amber" size="30" />
          </div>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { fileToDownload } from "@/utils/helpers";

export default {
  props: {
    payroll: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      downloading: false
    };
  },
  computed: {},
  methods: {
    async downloadFile() {
      this.downloading = true;
      const { payrollCsv } = this.payroll;
      if (payrollCsv) {
        await fileToDownload(payrollCsv);
      }
      this.downloading = false;
    }
  }
};
</script>

<style lang="scss"></style>
